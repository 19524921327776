import React, { useState } from 'react';
import { Modal, Collapse } from 'antd';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import Button from '@mui/joy/Button';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

const WeeklySummaryCard = ({ weekData, summaryData, appIncomes, calculateTotalIncome, calculateTotalExpenses }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleNavigateToRozliczenia = () => {
    navigate('/rozliczenia');
  };

  const renderSummaryItem = (label, value) => {
    const numericValue = typeof value === 'number' ? value.toFixed(2) : '0.00';
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
        <span><strong>{label}:</strong></span>
        <span>{numericValue} zł</span>
      </div>
    );
  };

  const targetAmount = (weekData.total * 1.2).toFixed(2);
  const progressValue = Math.min((weekData.total / targetAmount) * 100, 100);

  return (
    <div>
      <Card variant="solid" sx={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }} invertedColors>
        
        <CardContent orientation="horizontal">
          <CircularProgress size="lg" determinate value={progressValue} color="primary">
            <SvgIcon sx={{ color: 'white' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                />
              </svg>
            </SvgIcon>
          </CircularProgress>
          <CardContent>
            <Typography level="body-md" sx={{ color: 'white' }}>Ostatnia wypłata</Typography>
            <Typography level="h2" sx={{ color: 'white' }}>{weekData.total} zł</Typography>
            <Typography level="body-sm" color="neutral">Cel: {targetAmount} zł</Typography>
          </CardContent>
        </CardContent>
        <CardActions>
          <Button variant="soft" size="sm" sx={{ color: 'white', borderRadius: '10px', width:'100%' }} onClick={handleModalOpen}>
            Szczegóły
          </Button>
          <Button 
            variant="solid" 
            size="sm" 
            sx={{ backgroundColor: '#e4b61a', color: 'black', borderRadius: '10px', width:'100%' }} 
            onClick={handleNavigateToRozliczenia}
          >
            Inne rozliczenie
          </Button>
        </CardActions>
      </Card>

      {/* Модальное окно */}
      <Modal
        title="Szczegóły rozliczenia"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Collapse defaultActiveKey={['1']} style={{ borderRadius: '10px', overflow: 'hidden' }}>
          <Panel
            header={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Przychody:</span>
                <span>{formatCurrency(calculateTotalIncome())}</span>
              </div>
            }
            key="1"
          >
            {Object.entries(appIncomes).map(([appId, netto]) => (
              renderSummaryItem(appId, netto)
            ))}
            {renderSummaryItem('Bonus Partnera', summaryData.bonusPartnera)}
            {renderSummaryItem('Zwrot VAT', summaryData.vat_bonus)}
          </Panel>
          <Panel
            header={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Wydatki:</span>
                <span>{formatCurrency(calculateTotalExpenses())}</span>
              </div>
            }
            key="2"
          >
            {renderSummaryItem('Należny podatek VAT', (summaryData.vat_dodatkowy || 0) + (summaryData.vat_przejazdy || 0))}
            {renderSummaryItem('Wynajem', summaryData.wynajem)}
            {renderSummaryItem('Partner', summaryData.partner)}
            {renderSummaryItem('Ubezpieczenie społeczne i zdrowotne', summaryData.zus)}
            {renderSummaryItem('Inne wydatki', summaryData.inne)}
          </Panel>
        </Collapse>
        <Button 
          variant="solid" 
          size="sm" 
          sx={{ backgroundColor: '#e4b61a', color: 'black', borderRadius: '10px', width:'100%', marginTop: '20px' }} 
          onClick={handleNavigateToRozliczenia}
        >
          Zobacz pełne rozliczenie
        </Button>
      </Modal>
    </div>
  );
};

const formatCurrency = (value) => {
  const numericValue = Math.abs(Number(value));
  return !isNaN(numericValue) ? numericValue.toFixed(2) + ' zł' : '0.00 zł';
};

export default WeeklySummaryCard;
